import React from "react";
import { AppBar } from "@material-ui/core";

import { TextDataScannerResult } from "scanbot-web-sdk/@types";

import { NavigationContent } from "./subviews/navigation-content";
import { Toast } from "./subviews/toast";
import FeatureList from "./subviews/feature-list";
import { BottomBar } from "./subviews/bottom-bar";

import ImageResultsPage from "./pages/image-results-page";
import ImageDetailPage from "./pages/image-detail-page";

import Pages from "./model/pages";
import { ScanbotSdkService } from "./service/scanbot-sdk-service";
import { RoutePath, RoutingService } from "./service/routing-service";

import { ImageUtils } from "./utils/image-utils";
import { NavigationUtils } from "./utils/navigation-utils";
import { MiscUtils } from "./utils/misc-utils";
import DocumentScannerComponent from "./rtu-ui/document-scanner-component";
import { AnimationType } from "./rtu-ui/enum/animation-type";
import ErrorLabel from "./subviews/error-label";
import ReviewInvoicesPage from "./pages/review-invoices-page";
import { postDocumentToScan } from "./api/common";

export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      alert: undefined,
      activeImage: undefined,
      sdk: undefined,
      error: {
        message: undefined,
      },
    };
  }

  async componentDidMount() {
    const sdk = await ScanbotSdkService.instance.initialize();
    this.setState({ sdk: sdk });

    RoutingService.instance.observeChanges(() => {
      this.forceUpdate();
    });

    await ScanbotSdkService.instance.setLicenseFailureHandler((error: any) => {
      RoutingService.instance.reset();

      this.setState({ error: { message: error } });
      if (this._documentScanner?.isVisible()) {
        this._documentScanner?.pop();
      }
    });
  }

  onBackPress() {
    RoutingService.instance.back();
  }

  navigation?: any;

  toolbarHeight() {
    return (this.navigation as HTMLHeadingElement)?.clientHeight ?? 0;
  }

  containerHeight() {
    if (!this.navigation) {
      return "100%";
    }
    return window.innerHeight - 2 * this.toolbarHeight() ?? 0;
  }

  render() {
    return (
      <div>
        {this.documentScanner()}

        <Toast
          alert={this.state.alert}
          onClose={() => this.setState({ alert: undefined })}
        />

        <AppBar
          position="fixed"
          ref={(ref) => (this.navigation = ref)}
          style={{ zIndex: 19 }}
        >
          <NavigationContent
            backVisible={!NavigationUtils.isAtRoot()}
            onBackClick={() => this.onBackPress()}
          />
        </AppBar>
        <div
          style={{
            height: this.containerHeight(),
            marginTop: this.toolbarHeight(),
          }}
        >
          {this.decideContent()}
        </div>
        <BottomBar
          hidden={
            NavigationUtils.isAtRoot() || NavigationUtils.isAtReviewInvoices()
          }
          height={this.toolbarHeight()}
          buttons={this.decideButtons()}
        />
      </div>
    );
  }

  _documentScannerHtmlComponent: any;
  _documentScanner?: DocumentScannerComponent | null;
  documentScanner() {
    if (!this._documentScannerHtmlComponent) {
      this._documentScannerHtmlComponent = (
        <DocumentScannerComponent
          ref={(ref) => (this._documentScanner = ref)}
          sdk={this.state.sdk}
          onDocumentDetected={this.onDocumentDetected.bind(this)}
        />
      );
    }
    return this._documentScannerHtmlComponent;
  }

  decideContent() {
    const route = NavigationUtils.findRoute();

    if (NavigationUtils.isAtRoot() || route === RoutePath.DocumentScanner) {
      return (
        <div>
          <ErrorLabel message={this.state.error.message} />
          <FeatureList onItemClick={this.onFeatureClick.bind(this)} />
        </div>
      );
    }

    if (route === RoutePath.ReviewInvoices) {
      return <ReviewInvoicesPage />;
    }

    if (route === RoutePath.ImageDetails) {
      if (!Pages.instance.hasActiveItem()) {
        RoutingService.instance.reset();
        return null;
      }
      return <ImageDetailPage image={this.state.activeImage} />;
    }
    if (route === RoutePath.ImageResults) {
      return (
        <ImageResultsPage
          sdk={this.state.sdk}
          onDetailButtonClick={async (index: number) => {
            Pages.instance.setActiveItem(index);
            this.setState({
              activeImage:
                await ScanbotSdkService.instance.documentImageAsBase64(index),
            });
            RoutingService.instance.route(RoutePath.ImageDetails, {
              index: index,
            });
          }}
        />
      );
    }
  }

  private decideButtons() {
    const route = NavigationUtils.findRoute();
    if (route === RoutePath.DocumentScanner) {
      return [
        { text: Pages.instance.count() + " PAGES", action: undefined },
        { text: "DONE", action: this.onBackPress.bind(this), right: true },
      ];
    }
    if (route === RoutePath.ImageResults) {
      return [{ text: "SUBMIT", action: this.submitDocument.bind(this) }];
    }
    if (route === RoutePath.ImageDetails) {
      return [
        { text: "DELETE", action: this.deletePage.bind(this), right: true },
      ];
    }
  }

  async detect() {
    await ScanbotSdkService.instance.croppingView?.detect();
  }

  async rotate() {
    await ScanbotSdkService.instance.croppingView?.rotate(1);
  }

  async applyCrop() {
    const result = await ScanbotSdkService.instance.croppingView?.apply();
    Pages.instance.updateActiveItem(result);
    await ScanbotSdkService.instance.reapplyFilter();
    this.onBackPress();
    const index = Pages.instance.getActiveIndex();
    this.setState({
      activeImage: await ScanbotSdkService.instance.documentImageAsBase64(
        index
      ),
    });
  }

  async submitDocument() {
    const bytes = await ScanbotSdkService.instance.generatePDF(
      Pages.instance.get()
    );

    const invoicePDF = new Blob([bytes], { type: `application/pdf` });

    // await ScanbotSdkService.instance
    //   .generatePDF(Pages.instance.get())
    //   .then((response) => {
    //     console.log(response);

    const companyKey = MiscUtils.generateCompanyKey("Crash Champions");

    console.log(companyKey);

    postDocumentToScan(invoicePDF, companyKey).then((res) => {
      RoutingService.instance.route(RoutePath.ReviewInvoices);
    });
    //   });
  }

  async savePDF() {
    const bytes = await ScanbotSdkService.instance.generatePDF(
      Pages.instance.get()
    );
    ImageUtils.saveBytes(bytes, MiscUtils.generateUUID() + ".pdf");
  }

  deletePage() {
    Pages.instance.removeActiveItem();
    RoutingService.instance.route(RoutePath.ImageResults);
  }

  async onDocumentDetected(result: any) {
    Pages.instance.add(result);
    ScanbotSdkService.instance.sdk?.utils.flash();

    console.log("Document detection result:", result);
    const blurDetector = await ScanbotSdkService.instance.createBlurDetector();
    console.log(
      "estimateBlurrinessOnBuffer",
      await blurDetector?.estimateBlurrinessOnBuffer(result.original)
    );
    await blurDetector?.release();
  }
  async onTextDataDetected(textData: TextDataScannerResult) {
    if (!textData) return;

    if (textData.validated) {
      ScanbotSdkService.instance.textDataScanner?.pauseDetection();

      alert(textData.text);

      setTimeout(() => {
        ScanbotSdkService.instance.textDataScanner?.resumeDetection();
      }, 500);
    }
  }

  async onFeatureClick(feature: any) {
    // const valid = await ScanbotSdkService.instance.isLicenseValid();
    // if (!valid) {
    //   console.error(
    //     "License invalid or expired. ScanbotSDK features not available"
    //   );
    //   return;
    // }

    if (feature.id === RoutePath.DocumentScanner) {
      this._documentScanner?.push(AnimationType.PushRight);
      return;
    }

    if (feature.route) {
      RoutingService.instance.route(feature.route);
      return;
    }

    if (feature.id === RoutePath.LicenseInfo) {
      const info = await this.state.sdk?.getLicenseInfo();
      const color = info?.status === "Trial" ? "success" : "error";
      this.setState({ alert: { color: color, text: JSON.stringify(info) } });
    } else if (feature.id === RoutePath.DocumentOnJpeg) {
      const image = await ImageUtils.pick(
        ImageUtils.MIME_TYPE_JPEG,
        document.getElementById(feature.id) as any
      );

      const contourDetectionResult =
        await ScanbotSdkService.instance.detectDocument(image.original);
      if (
        contourDetectionResult.success === true &&
        contourDetectionResult.polygon
      ) {
        const cropped = await ScanbotSdkService.instance.cropAndRotateImageCcw(
          image.original,
          contourDetectionResult.polygon,
          0
        );
        const documentDetectionResult = {
          ...contourDetectionResult,
          original: image.original,
          cropped: cropped,
        };

        Pages.instance.add(documentDetectionResult);
        alert("Detection successful");
      } else {
        alert("Detection failed");
      }
    }
  }
}
