import { RoutePath } from "../service/routing-service";

export class Features {
  public static LIST = [
    {
      id: RoutePath.DocumentScanner,
      name: "Scan Documents",
      route: RoutePath.DocumentScanner,
    },
    {
      id: RoutePath.ImageResults,
      name: "View Documents",
      route: RoutePath.ImageResults,
    },
    {
      id: RoutePath.ReviewInvoices,
      name: "Review Invoices",
      route: RoutePath.ReviewInvoices,
    },
  ];
}
