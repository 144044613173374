import { useState } from "react";

const InvoiceDetails = ({ content, goBack }: any) => {
  const [isDeleting, setIsDelete] = useState(false);
  const {
    id,
    companyKey,
    scanInvoiceStatus,
    scanDocumentStatus,
    invoiceScanResults,
    invoiceLineItemResults,
  } = content;

  const deleteEntry = async () => {
    setIsDelete(true);
    await fetch(
      `${process.env.REACT_APP_API_URL}/invoice/invoice-scans/historical/${id}`,
      {
        method: "DELETE",
        headers: { "X-CSRF": "1", mode: "no-cors" },
        credentials: "include",
      }
    ).then((res) => {
      console.log("deleted ", res);
      setIsDelete(false);
      goBack(0);
    });
  };

  const invoiceLineItems = invoiceLineItemResults.map((content: any) => {
    const { lineItems } = content;

    return lineItems.map((line: any) => {
      const itemsData = [];

      for (const key in line) {
        const indexedItem = line[key];
        itemsData.push([indexedItem, key]);
      }

      const confidenceColor = line.confidence < 80 ? "#fbd7d7" : "#e5fde5";
      const itemName = line.itemName;

      return (
        <table
          key={line.itemName}
          className="invoice-details-table"
          style={{
            marginBottom: "55px",
            width: "100%",
            background: confidenceColor,
          }}
        >
          <tbody>
            <tr>
              <td></td>
              <td>Scanned</td>
              <td>PTML</td>
            </tr>
            {itemsData.map((data: any) => {
              return (
                <tr
                  key={itemName + data[1]}
                  style={{
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <td
                    style={{
                      width: "25%",
                      verticalAlign: "middle",
                    }}
                  >
                    {data[1]}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <input type="text" defaultValue={data[0]} />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <input type="text" defaultValue={data[0]} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  });

  return (
    <div key={companyKey + id} style={{ padding: "10px" }}>
      <p
        style={{ marginBottom: "20px" }}
        onClick={() => {
          goBack(0);
        }}
      >
        ⬅️ Back to the list
      </p>

      <details open className="invoice-row">
        <summary>Invoice Details</summary>
        <h4>
          {companyKey} #{id}
        </h4>
        <p>
          scanDocumentStatus: <b>{scanDocumentStatus}</b>
        </p>
        <p>
          scanInvoiceStatus: <b>{scanInvoiceStatus}</b>
        </p>
      </details>

      <br />
      <details className="invoice-row">
        <summary>Invoice Scan Results</summary>
        <br />
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontSize: "12px", width: "20%" }}></p>
          <p style={{ fontSize: "12px", width: "40%" }}>Scanned</p>
          <p style={{ fontSize: "12px", width: "40%" }}>PTML</p>
        </div>
        {invoiceScanResults.length > 0 &&
          invoiceScanResults.map(
            ({ aliasKey, questionAnswers, confidence }: any) => {
              const { answer } = questionAnswers[0];
              const confidenceColor = confidence > 80 ? "#fbd7d7" : "#e5fde5";

              if (answer === "" || !aliasKey) {
                return null;
              }

              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  key={aliasKey}
                >
                  <p style={{ fontSize: "12px", width: "20%" }}>
                    {aliasKey.toLowerCase().replace("_", " ")}
                  </p>
                  <p style={{ fontSize: "12px", width: "40%" }}>
                    <input
                      style={{ backgroundColor: confidenceColor }}
                      type="text"
                      defaultValue={answer}
                    />
                  </p>
                  <p style={{ fontSize: "12px", width: "40%" }}>
                    <input
                      style={{ backgroundColor: confidenceColor }}
                      type="text"
                      defaultValue={answer}
                    />
                  </p>
                  <br />
                </div>
              );
            }
          )}
      </details>
      <br />
      <details className="invoice-row">
        <summary>Line items Results</summary>
        <br />
        {invoiceLineItemResults.length > 0 && <> {invoiceLineItems}</>}
      </details>
      <br />
      <fieldset className="invoice-row">
        <button>Send to CCC</button>
        <br />
        <br />
        <button
          type="button"
          disabled={isDeleting}
          onClick={async () => {
            await deleteEntry();
          }}
        >
          {isDeleting ? "Deleting..." : "Delete"}
        </button>
      </fieldset>
    </div>
  );
};

export default InvoiceDetails;
