export const AuthActionTypeList = {
  GET_USER: "get-user",
  LOGIN: "login",
  LOGGED_IN: "logged-in",
  LOGOUT: "logout",
  NO_DATA: "no-data",
  SET_USER: "set-user",
  USER_DATA: "user-data",
};

export type UserData = {
  amr?: string;
  sid?: string;
  sub?: string;
  auth_time?: string;
  idp?: string;
  name?: string;
  roles?: string[];
  company_displayid?: string;
};

type AuthActionTypeKey = keyof typeof AuthActionTypeList;
export type AuthActions = typeof AuthActionTypeList[AuthActionTypeKey];

export type AuthWorkerResponseType = {
  action: AuthActions;
  userData: UserData | null;
};

export const getApiBaseUrl = () => {
  return `${process.env.REACT_APP_API_URL}/invoice`;
};
