const baseApi = `${process.env.REACT_APP_API_URL}/invoice`;

export const getInvoiceHistorical = async (id: number) => {
  return fetch(`${baseApi}/historical/${id}`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  });
};

export const getAllInvoiceHistorical = async () => {
  await fetch(`${baseApi}/historical`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
};

export const getInvoiceStatus = async (id: number) => {
  return fetch(`${baseApi}/scan-status/${id}`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  });
};

export const fetchHistory = async (id: number) => {
  return fetch(`${baseApi}/invoice-scans/historical/${id}`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  });
};

export const postDocumentToScan = async (
  invoiceFile: any,
  companyKey: string
) => {
  var data = new FormData();
  data.append("invoiceFile", invoiceFile);
  data.append("companyKey", companyKey);

  return fetch(
    `${process.env.REACT_APP_API_URL}/invoice/invoice-scans/${companyKey}`,
    {
      method: "POST",
      body: data,
      headers: { "X-CSRF": "1", mode: "no-cors" },
      credentials: "include",
    }
  );
};
